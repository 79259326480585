/*
..######..##....##.####.##....##....########..########.########....###....##.....##.##.......########
.##....##.##...##...##..###...##....##.....##.##.......##.........##.##...##.....##.##..........##...
.##.......##..##....##..####..##....##.....##.##.......##........##...##..##.....##.##..........##...
..######..#####.....##..##.##.##....##.....##.######...######...##.....##.##.....##.##..........##...
.......##.##..##....##..##..####....##.....##.##.......##.......#########.##.....##.##..........##...
.##....##.##...##...##..##...###....##.....##.##.......##.......##.....##.##.....##.##..........##...
..######..##....##.####.##....##....########..########.##.......##.....##..#######..########....##...
*/
a {
  color: #1dc6c8;
}

a:hover {
  color: #1dc6c8;
}

ul.list-bullets li:before {
  color: #1dc6c8;
}

.rating {
  color: #fd962c;
}

.bg-primary {
  background-color: #1dc6c8 !important;
}

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #179a9b !important;
}

.bg-primary-light {
  background-color: rgba(29, 198, 200, 0.2) !important;
}

a.bg-primary-light:hover, a.bg-primary-light:focus,
button.bg-primary-light:hover,
button.bg-primary-light:focus {
  background-color: rgba(23, 154, 155, 0.2) !important;
}

.bg-secondary {
  background-color: #fd962c !important;
}

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #f47d02 !important;
}

.bg-success {
  background-color: #28a745 !important;
}

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #1e7e34 !important;
}

.bg-info {
  background-color: #523da2 !important;
}

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #3f2f7d !important;
}

.bg-warning {
  background-color: #fd962c !important;
}

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #f47d02 !important;
}

.bg-danger {
  background-color: #df3603 !important;
}

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #ad2a02 !important;
}

.bg-light {
  background-color: #f8f9fa !important;
}

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae0e5 !important;
}

.bg-dark {
  background-color: #333333 !important;
}

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1a1a1a !important;
}

.bg-purple {
  background-color: #523da2 !important;
}

a.bg-purple:hover, a.bg-purple:focus,
button.bg-purple:hover,
button.bg-purple:focus {
  background-color: #3f2f7d !important;
}

.text-primary {
  color: #1dc6c8 !important;
}

a.text-primary:hover, a.text-primary:focus {
  color: #179a9b !important;
}

.text-primary-light {
  color: rgba(29, 198, 200, 0.2) !important;
}

a.text-primary-light:hover, a.text-primary-light:focus {
  color: rgba(23, 154, 155, 0.2) !important;
}

.text-secondary {
  color: #fd962c !important;
}

a.text-secondary:hover, a.text-secondary:focus {
  color: #f47d02 !important;
}

.text-success {
  color: #28a745 !important;
}

a.text-success:hover, a.text-success:focus {
  color: #1e7e34 !important;
}

.text-info {
  color: #523da2 !important;
}

a.text-info:hover, a.text-info:focus {
  color: #3f2f7d !important;
}

.text-warning {
  color: #fd962c !important;
}

a.text-warning:hover, a.text-warning:focus {
  color: #f47d02 !important;
}

.text-danger {
  color: #df3603 !important;
}

a.text-danger:hover, a.text-danger:focus {
  color: #ad2a02 !important;
}

.text-light {
  color: #f8f9fa !important;
}

a.text-light:hover, a.text-light:focus {
  color: #dae0e5 !important;
}

.text-dark {
  color: #333333 !important;
}

a.text-dark:hover, a.text-dark:focus {
  color: #1a1a1a !important;
}

.text-purple {
  color: #523da2 !important;
}

a.text-purple:hover, a.text-purple:focus {
  color: #3f2f7d !important;
}

.alert-primary {
  color: #176f70;
  background-color: #d2f4f4;
  border-color: #1dc6c8;
}

.alert-primary hr {
  border-top-color: #1ab0b2;
}

.alert-primary .alert-link {
  color: #0e4546;
}

.alert-primary-light {
  color: rgba(18, 36, 37, 0.584);
  background-color: rgba(249, 253, 254, 0.84);
  border-color: rgba(29, 198, 200, 0.2);
}

.alert-primary-light hr {
  border-top-color: rgba(26, 176, 178, 0.2);
}

.alert-primary-light .alert-link {
  color: rgba(1, 3, 3, 0.584);
}

.alert-secondary {
  color: #8c561f;
  background-color: #ffead5;
  border-color: #fd962c;
}

.alert-secondary hr {
  border-top-color: #fd8913;
}

.alert-secondary .alert-link {
  color: #623c16;
}

.alert-success {
  color: #1d5f2c;
  background-color: #d4edda;
  border-color: #28a745;
}

.alert-success hr {
  border-top-color: #23923d;
}

.alert-success .alert-link {
  color: #11381a;
}

.alert-info {
  color: #33285c;
  background-color: #dcd8ec;
  border-color: #523da2;
}

.alert-info hr {
  border-top-color: #49368f;
}

.alert-info .alert-link {
  color: #1f1938;
}

.alert-warning {
  color: #8c561f;
  background-color: #ffead5;
  border-color: #fd962c;
}

.alert-warning hr {
  border-top-color: #fd8913;
}

.alert-warning .alert-link {
  color: #623c16;
}

.alert-danger {
  color: #7c240a;
  background-color: #f9d7cd;
  border-color: #df3603;
}

.alert-danger hr {
  border-top-color: #c63003;
}

.alert-danger .alert-link {
  color: #4d1606;
}

.alert-light {
  color: #898a8a;
  background-color: #fefefe;
  border-color: #f8f9fa;
}

.alert-light hr {
  border-top-color: #e9ecef;
}

.alert-light .alert-link {
  color: #707070;
}

.alert-dark {
  color: #232323;
  background-color: #d6d6d6;
  border-color: #333333;
}

.alert-dark hr {
  border-top-color: #262626;
}

.alert-dark .alert-link {
  color: #0a0a0a;
}

.alert-purple {
  color: #33285c;
  background-color: #dcd8ec;
  border-color: #523da2;
}

.alert-purple hr {
  border-top-color: #49368f;
}

.alert-purple .alert-link {
  color: #1f1938;
}

.bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before {
  border-top-color: #1dc6c8;
}

.bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^="right"] .arrow::before {
  border-right-color: #1dc6c8;
}

.bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
  border-bottom-color: #1dc6c8;
}

.bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^="left"] .arrow::before {
  border-left-color: #1dc6c8;
}

.tooltip-inner {
  background-color: #1dc6c8;
}

.btn-primary {
  color: #fff;
  background-color: #1dc6c8;
  border-color: #1dc6c8;
}

.btn-primary:hover {
  color: #fff;
  background-color: #18a5a7;
  border-color: #179a9b;
}

.btn-primary:focus, .btn-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(29, 198, 200, 0.5);
}

.btn-primary.disabled, .btn-primary:disabled {
  color: #fff;
  background-color: #1dc6c8;
  border-color: #1dc6c8;
}

.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #179a9b;
  border-color: #158f90;
}

.btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(29, 198, 200, 0.5);
}

.btn-primary-light {
  color: #fff;
  background-color: rgba(29, 198, 200, 0.2);
  border-color: rgba(29, 198, 200, 0.2);
}

.btn-primary-light:hover {
  color: #fff;
  background-color: rgba(24, 165, 167, 0.2);
  border-color: rgba(23, 154, 155, 0.2);
}

.btn-primary-light:focus, .btn-primary-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(29, 198, 200, 0.5);
}

.btn-primary-light.disabled, .btn-primary-light:disabled {
  color: #fff;
  background-color: rgba(29, 198, 200, 0.2);
  border-color: rgba(29, 198, 200, 0.2);
}

.btn-primary-light:not(:disabled):not(.disabled):active, .btn-primary-light:not(:disabled):not(.disabled).active,
.show > .btn-primary-light.dropdown-toggle {
  color: #fff;
  background-color: rgba(23, 154, 155, 0.2);
  border-color: rgba(21, 143, 144, 0.2);
}

.btn-primary-light:not(:disabled):not(.disabled):active:focus, .btn-primary-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-primary-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(29, 198, 200, 0.5);
}

.btn-secondary {
  color: #fff;
  background-color: #fd962c;
  border-color: #fd962c;
}

.btn-secondary:hover {
  color: #fff;
  background-color: #fd8306;
  border-color: #f47d02;
}

.btn-secondary:focus, .btn-secondary.focus {
  box-shadow: 0 0 0 0.2rem rgba(253, 150, 44, 0.5);
}

.btn-secondary.disabled, .btn-secondary:disabled {
  color: #fff;
  background-color: #fd962c;
  border-color: #fd962c;
}

.btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
.show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #f47d02;
  border-color: #e77602;
}

.btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(253, 150, 44, 0.5);
}

.btn-success {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}

.btn-success:hover {
  color: #fff;
  background-color: #218838;
  border-color: #1e7e34;
}

.btn-success:focus, .btn-success.focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

.btn-success.disabled, .btn-success:disabled {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}

.btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active,
.show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #1e7e34;
  border-color: #1c7430;
}

.btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus,
.show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

.btn-info {
  color: #fff;
  background-color: #523da2;
  border-color: #523da2;
}

.btn-info:hover {
  color: #fff;
  background-color: #443386;
  border-color: #3f2f7d;
}

.btn-info:focus, .btn-info.focus {
  box-shadow: 0 0 0 0.2rem rgba(82, 61, 162, 0.5);
}

.btn-info.disabled, .btn-info:disabled {
  color: #fff;
  background-color: #523da2;
  border-color: #523da2;
}

.btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active,
.show > .btn-info.dropdown-toggle {
  color: #fff;
  background-color: #3f2f7d;
  border-color: #3b2c74;
}

.btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus,
.show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(82, 61, 162, 0.5);
}

.btn-warning {
  color: #fff;
  background-color: #fd962c;
  border-color: #fd962c;
}

.btn-warning:hover {
  color: #fff;
  background-color: #fd8306;
  border-color: #f47d02;
}

.btn-warning:focus, .btn-warning.focus {
  box-shadow: 0 0 0 0.2rem rgba(253, 150, 44, 0.5);
}

.btn-warning.disabled, .btn-warning:disabled {
  color: #fff;
  background-color: #fd962c;
  border-color: #fd962c;
}

.btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active,
.show > .btn-warning.dropdown-toggle {
  color: #fff;
  background-color: #f47d02;
  border-color: #e77602;
}

.btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus,
.show > .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(253, 150, 44, 0.5);
}

.btn-danger {
  color: #fff;
  background-color: #df3603;
  border-color: #df3603;
}

.btn-danger:hover {
  color: #fff;
  background-color: #b92d02;
  border-color: #ad2a02;
}

.btn-danger:focus, .btn-danger.focus {
  box-shadow: 0 0 0 0.2rem rgba(223, 54, 3, 0.5);
}

.btn-danger.disabled, .btn-danger:disabled {
  color: #fff;
  background-color: #df3603;
  border-color: #df3603;
}

.btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active,
.show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #ad2a02;
  border-color: #a02702;
}

.btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus,
.show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(223, 54, 3, 0.5);
}

.btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-light:hover {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
}

.btn-light:focus, .btn-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.btn-light.disabled, .btn-light:disabled {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active,
.show > .btn-light.dropdown-toggle {
  color: #212529;
  background-color: #dae0e5;
  border-color: #d3d9df;
}

.btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.btn-dark {
  color: #fff;
  background-color: #333333;
  border-color: #333333;
}

.btn-dark:hover {
  color: #fff;
  background-color: #202020;
  border-color: #1a1a1a;
}

.btn-dark:focus, .btn-dark.focus {
  box-shadow: 0 0 0 0.2rem rgba(51, 51, 51, 0.5);
}

.btn-dark.disabled, .btn-dark:disabled {
  color: #fff;
  background-color: #333333;
  border-color: #333333;
}

.btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active,
.show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #1a1a1a;
  border-color: #131313;
}

.btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus,
.show > .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(51, 51, 51, 0.5);
}

.btn-purple {
  color: #fff;
  background-color: #523da2;
  border-color: #523da2;
}

.btn-purple:hover {
  color: #fff;
  background-color: #443386;
  border-color: #3f2f7d;
}

.btn-purple:focus, .btn-purple.focus {
  box-shadow: 0 0 0 0.2rem rgba(82, 61, 162, 0.5);
}

.btn-purple.disabled, .btn-purple:disabled {
  color: #fff;
  background-color: #523da2;
  border-color: #523da2;
}

.btn-purple:not(:disabled):not(.disabled):active, .btn-purple:not(:disabled):not(.disabled).active,
.show > .btn-purple.dropdown-toggle {
  color: #fff;
  background-color: #3f2f7d;
  border-color: #3b2c74;
}

.btn-purple:not(:disabled):not(.disabled):active:focus, .btn-purple:not(:disabled):not(.disabled).active:focus,
.show > .btn-purple.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(82, 61, 162, 0.5);
}

.btn-outline-primary {
  color: #1dc6c8;
  background-color: transparent;
  background-image: none;
  border-color: #1dc6c8;
}

.btn-outline-primary:hover {
  color: #fff;
  background-color: #1dc6c8;
  border-color: #1dc6c8;
}

.btn-outline-primary:focus, .btn-outline-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(29, 198, 200, 0.5);
}

.btn-outline-primary.disabled, .btn-outline-primary:disabled {
  color: #1dc6c8;
  background-color: transparent;
}

.btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active,
.show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #1dc6c8;
  border-color: #1dc6c8;
}

.btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(29, 198, 200, 0.5);
}

.btn-outline-primary-light {
  color: rgba(29, 198, 200, 0.2);
  background-color: transparent;
  background-image: none;
  border-color: rgba(29, 198, 200, 0.2);
}

.btn-outline-primary-light:hover {
  color: #fff;
  background-color: rgba(29, 198, 200, 0.2);
  border-color: rgba(29, 198, 200, 0.2);
}

.btn-outline-primary-light:focus, .btn-outline-primary-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(29, 198, 200, 0.5);
}

.btn-outline-primary-light.disabled, .btn-outline-primary-light:disabled {
  color: rgba(29, 198, 200, 0.2);
  background-color: transparent;
}

.btn-outline-primary-light:not(:disabled):not(.disabled):active, .btn-outline-primary-light:not(:disabled):not(.disabled).active,
.show > .btn-outline-primary-light.dropdown-toggle {
  color: #fff;
  background-color: rgba(29, 198, 200, 0.2);
  border-color: rgba(29, 198, 200, 0.2);
}

.btn-outline-primary-light:not(:disabled):not(.disabled):active:focus, .btn-outline-primary-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-primary-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(29, 198, 200, 0.5);
}

.btn-outline-secondary {
  color: #fd962c;
  background-color: transparent;
  background-image: none;
  border-color: #fd962c;
}

.btn-outline-secondary:hover {
  color: #fff;
  background-color: #fd962c;
  border-color: #fd962c;
}

.btn-outline-secondary:focus, .btn-outline-secondary.focus {
  box-shadow: 0 0 0 0.2rem rgba(253, 150, 44, 0.5);
}

.btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
  color: #fd962c;
  background-color: transparent;
}

.btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active,
.show > .btn-outline-secondary.dropdown-toggle {
  color: #fff;
  background-color: #fd962c;
  border-color: #fd962c;
}

.btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(253, 150, 44, 0.5);
}

.btn-outline-success {
  color: #28a745;
  background-color: transparent;
  background-image: none;
  border-color: #28a745;
}

.btn-outline-success:hover {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}

.btn-outline-success:focus, .btn-outline-success.focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

.btn-outline-success.disabled, .btn-outline-success:disabled {
  color: #28a745;
  background-color: transparent;
}

.btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active,
.show > .btn-outline-success.dropdown-toggle {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}

.btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.5);
}

.btn-outline-info {
  color: #523da2;
  background-color: transparent;
  background-image: none;
  border-color: #523da2;
}

.btn-outline-info:hover {
  color: #fff;
  background-color: #523da2;
  border-color: #523da2;
}

.btn-outline-info:focus, .btn-outline-info.focus {
  box-shadow: 0 0 0 0.2rem rgba(82, 61, 162, 0.5);
}

.btn-outline-info.disabled, .btn-outline-info:disabled {
  color: #523da2;
  background-color: transparent;
}

.btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active,
.show > .btn-outline-info.dropdown-toggle {
  color: #fff;
  background-color: #523da2;
  border-color: #523da2;
}

.btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(82, 61, 162, 0.5);
}

.btn-outline-warning {
  color: #fd962c;
  background-color: transparent;
  background-image: none;
  border-color: #fd962c;
}

.btn-outline-warning:hover {
  color: #fff;
  background-color: #fd962c;
  border-color: #fd962c;
}

.btn-outline-warning:focus, .btn-outline-warning.focus {
  box-shadow: 0 0 0 0.2rem rgba(253, 150, 44, 0.5);
}

.btn-outline-warning.disabled, .btn-outline-warning:disabled {
  color: #fd962c;
  background-color: transparent;
}

.btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active,
.show > .btn-outline-warning.dropdown-toggle {
  color: #fff;
  background-color: #fd962c;
  border-color: #fd962c;
}

.btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(253, 150, 44, 0.5);
}

.btn-outline-danger {
  color: #df3603;
  background-color: transparent;
  background-image: none;
  border-color: #df3603;
}

.btn-outline-danger:hover {
  color: #fff;
  background-color: #df3603;
  border-color: #df3603;
}

.btn-outline-danger:focus, .btn-outline-danger.focus {
  box-shadow: 0 0 0 0.2rem rgba(223, 54, 3, 0.5);
}

.btn-outline-danger.disabled, .btn-outline-danger:disabled {
  color: #df3603;
  background-color: transparent;
}

.btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active,
.show > .btn-outline-danger.dropdown-toggle {
  color: #fff;
  background-color: #df3603;
  border-color: #df3603;
}

.btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(223, 54, 3, 0.5);
}

.btn-outline-light {
  color: #f8f9fa;
  background-color: transparent;
  background-image: none;
  border-color: #f8f9fa;
}

.btn-outline-light:hover {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-outline-light:focus, .btn-outline-light.focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.btn-outline-light.disabled, .btn-outline-light:disabled {
  color: #f8f9fa;
  background-color: transparent;
}

.btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active,
.show > .btn-outline-light.dropdown-toggle {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.btn-outline-dark {
  color: #333333;
  background-color: transparent;
  background-image: none;
  border-color: #333333;
}

.btn-outline-dark:hover {
  color: #fff;
  background-color: #333333;
  border-color: #333333;
}

.btn-outline-dark:focus, .btn-outline-dark.focus {
  box-shadow: 0 0 0 0.2rem rgba(51, 51, 51, 0.5);
}

.btn-outline-dark.disabled, .btn-outline-dark:disabled {
  color: #333333;
  background-color: transparent;
}

.btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active,
.show > .btn-outline-dark.dropdown-toggle {
  color: #fff;
  background-color: #333333;
  border-color: #333333;
}

.btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(51, 51, 51, 0.5);
}

.btn-outline-purple {
  color: #523da2;
  background-color: transparent;
  background-image: none;
  border-color: #523da2;
}

.btn-outline-purple:hover {
  color: #fff;
  background-color: #523da2;
  border-color: #523da2;
}

.btn-outline-purple:focus, .btn-outline-purple.focus {
  box-shadow: 0 0 0 0.2rem rgba(82, 61, 162, 0.5);
}

.btn-outline-purple.disabled, .btn-outline-purple:disabled {
  color: #523da2;
  background-color: transparent;
}

.btn-outline-purple:not(:disabled):not(.disabled):active, .btn-outline-purple:not(:disabled):not(.disabled).active,
.show > .btn-outline-purple.dropdown-toggle {
  color: #fff;
  background-color: #523da2;
  border-color: #523da2;
}

.btn-outline-purple:not(:disabled):not(.disabled):active:focus, .btn-outline-purple:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-purple.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(82, 61, 162, 0.5);
}

.btn-light,
.btn-outline-light {
  color: #fd962c;
}

.btn-nav:hover, .btn-nav:focus {
  background-color: #fff !important;
  color: #fd962c !important;
}

.qty .ui-spinner-input:focus {
  border-color: #fd962c;
}

.qty .ui-spinner-button:hover::after, .qty .ui-spinner-button:focus::after {
  border-color: #fd962c transparent;
}

.mCSB_scrollTools .mCSB_buttonDown,
.mCSB_scrollTools .mCSB_buttonUp {
  color: #fff;
}

.mCSB_scrollTools .mCSB_buttonDown:hover,
.mCSB_scrollTools .mCSB_buttonUp:hover {
  background-color: #fd962c;
}

.form-control,
.select2-container .select2-selection--single {
  border-color: #ced4da;
}

.form-control:focus,
.select2-container .select2-selection--single:focus {
  color: #444444;
  background-color: #fff;
  border-color: #1dc6c8;
  outline: 0;
  box-shadow: 0 0 0 0.2rem transparent;
}

.form-control:focus ~ .input-group-append .btn {
  border-color: #1dc6c8;
}

.form-control:focus ~ .form-control.date {
  color: #fd962c;
}

.form-control::placeholder {
  opacity: 1;
  color: #666666;
}

.form-control.flatpickr-input[readonly] {
  background-color: #fff;
}

.custom-checkbox .custom-control-label::after {
  background-image: linear-gradient(to bottom, #fd962c 100%, transparent 100%), linear-gradient(to right, #fd962c 100%, transparent 100%) !important;
}

.custom-radio .custom-control-label:before {
  background-color: #fd962c;
}

.custom-radio .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #fd962c;
}

.select2-dropdown .select2-search {
  border-bottom-color: #fd962c;
}

.select2-container .select2-dropdown .select2-results__option--highlighted[aria-selected] {
  background-color: #1dc6c8;
}

.noUi-horizontal.noUi-ltr .noUi-connect {
  background-color: #fd962c;
}

.noUi-horizontal.noUi-ltr .noUi-handle {
  background-color: #fd962c;
}

.progress-bar {
  color: #fff;
  background-color: #1dc6c8;
}

.modal-account__tabs .nav-link {
  background-color: rgba(29, 198, 200, 0.2);
}

.modal-account__tabs .nav-link:hover {
  background-color: rgba(29, 198, 200, 0.3);
}

.modal-account__tabs .nav-link.active {
  background-color: #ffffff;
  border-color: #ffffff;
}

@media (min-width: 768px) {
  .modal-account__list .nav-link:hover {
    background-color: rgba(29, 198, 200, 0.2);
    border-color: rgba(29, 198, 200, 0.2);
  }
}

.breadcrumb-item a {
  color: inherit;
}

.breadcrumb-item a:hover {
  color: #1dc6c8;
}

.page-link {
  color: #666666;
  background-color: #fff;
  border: 1px solid #dcdcdc;
}

.page-link:hover {
  color: #fff;
  background-color: #fd962c;
  border-color: #fd962c;
}

.page-item.active .page-link {
  color: #fff;
  background-color: #1dc6c8;
  border-color: #1dc6c8;
}

.page-item.disabled .page-link {
  color: #666666;
  background-color: #fff;
  border-color: #dcdcdc;
}

.page-content {
  border-bottom-color: #fd962c;
}

.main-nav {
  background-color: #1dc6c8;
}

.main-nav__list .nav-link {
  color: #fff;
  background-color: #1dc6c8;
}

.main-nav__list .nav-link:hover {
  background-color: #29dee0;
}

.main-nav__list .nav-link:hover span {
  border-bottom-color: currentColor;
}

.main-nav__list .nav-link:active:focus {
  background-color: #1cbdbf;
}

.main-nav__list .dropdown-menu .nav-link:focus, .main-nav__list .dropdown-menu .nav-link:hover, .main-nav__list .dropdown-menu .nav-link:active:focus {
  background-color: white;
}

.main-nav__search .form-control {
  box-shadow: inset 0 0 0 1px transparent;
}

.main-nav__search .form-control:focus {
  box-shadow: inset 0 0 0 1px #fd962c;
}

@media (max-width: 991px) {
  .main-nav {
    background-color: #f8f9fa;
  }
  .main-nav__list .nav-item.show > .nav-link {
    color: #1dc6c8;
  }
  .main-nav__list .nav-link {
    background-color: #f8f9fa;
    color: #212529;
  }
  .main-nav__list .nav-link:hover, .main-nav__list .nav-link:active:focus {
    background-color: #e9ecef;
    color: #1dc6c8;
  }
}

.card-intro__footer {
  background-color: rgba(29, 198, 200, 0.9);
}

.card-intro__rating .br-widget a {
  color: inherit;
}

.card-intro__rating .br-widget a::after {
  color: inherit !important;
}

.search-hotels__tabs .nav-link {
  border-color: #d6f9f9;
  background-color: #d6f9f9;
}

.search-hotels__tabs .nav-link:hover {
  background-color: #dffafa;
}

.search-hotels__tabs .nav-link.active {
  background-color: #fff;
  border-color: #fd962c;
  border-bottom-color: #adb5bd;
}

.search-hotels__form .select-local {
  background-color: rgba(29, 198, 200, 0.2);
}

.search-hotels__form .select-local .select2-selection--single {
  background-color: transparent;
}

.search-hotels__form .select-local .select2-selection--single:focus .select2-selection__rendered {
  border-color: #fd962c !important;
}

.search-hotels__form .form-control.date:focus ~ .date {
  border-bottom-color: #fd962c;
}

.card-hover {
  background-color: rgba(29, 198, 200, 0.94);
  box-shadow: 0px 0px 0px 1px rgba(29, 198, 200, 0.94);
}

.card-service .card-header {
  color: #1dc6c8;
}

.sortbar .nav-link.active {
  color: #1dc6c8;
}

.hotel-nav__menu .nav-link {
  color: #666666;
  background-color: rgba(29, 198, 200, 0.2);
}

.hotel-nav__menu .nav-link.active {
  color: #fff;
  background-color: rgba(29, 198, 200, 0.8);
}

.hotel-service__list .available {
  background-color: rgba(29, 198, 200, 0.2);
}

.hotel-status {
  background-color: rgba(29, 198, 200, 0.5);
}

.hotel-package__title:before {
  border-left-color: #fd962c;
}

.hotel-package__props .form-select .select2-container .select2-selection--single {
  background-color: rgba(29, 198, 200, 0.2);
}

.hotel-gallery__thumbs .swiper-slide.swiper-slide-active {
  border-color: #fd962c;
}

.blog-post__meta-tags a {
  color: inherit;
}

.blog-post__meta-tags a:hover {
  color: #fd962c;
}

.sidebar__list a {
  color: inherit;
}

.sidebar__list a:hover {
  color: #1dc6c8;
  text-decoration: none;
}

.sidebar__contacts a {
  color: inherit;
}

.sidebar__contacts a:hover {
  color: #1dc6c8;
}

.guests-room__desc {
  background-color: rgba(29, 198, 200, 0.2);
}

.guests-room__desc:after {
  border-color: rgba(29, 198, 200, 0.2) transparent transparent;
}

.guests-room__avatar:hover {
  border-color: #fd962c;
  box-shadow: 0 0 0 2px #fd962c;
}

.page-footer {
  color: #fff;
  background-color: rgba(29, 198, 200, 0.9);
}

.page-footer a {
  color: inherit;
}

.page-footer a:hover {
  color: #fd962c;
}

.page-footer__cover:before {
  background-color: rgba(29, 198, 200, 0.9);
}

.page-footer__copyright {
  color: rgba(255, 255, 255, 0.7);
}

.page-footer__copyright .hr {
  border-top-color: rgba(255, 255, 255, 0.5);
}

.footer-social {
  color: #fff;
}

.subscribe-form .form-control {
  border-color: #fff !important;
  color: #fff;
  background-color: transparent;
}

.subscribe-form .form-control::placeholder {
  color: inherit;
  opacity: 1;
}

.subscribe-form .btn {
  border-color: #fff !important;
  color: #666666;
}

.flatpickr-day.selected,
.flatpickr-day.startRange,
.flatpickr-day.endRange,
.flatpickr-day.selected.inRange,
.flatpickr-day.startRange.inRange,
.flatpickr-day.endRange.inRange,
.flatpickr-day.selected:focus,
.flatpickr-day.startRange:focus,
.flatpickr-day.endRange:focus,
.flatpickr-day.selected:hover,
.flatpickr-day.startRange:hover,
.flatpickr-day.endRange:hover,
.flatpickr-day.selected.prevMonthDay,
.flatpickr-day.startRange.prevMonthDay,
.flatpickr-day.endRange.prevMonthDay,
.flatpickr-day.selected.nextMonthDay,
.flatpickr-day.startRange.nextMonthDay,
.flatpickr-day.endRange.nextMonthDay {
  background: #1dc6c8;
  color: #fff;
  border-color: #1dc6c8;
}

.flatpickr-day.selected.startRange + .endRange,
.flatpickr-day.startRange.startRange + .endRange,
.flatpickr-day.endRange.startRange + .endRange {
  box-shadow: -10px 0 0 #1dc6c8;
}

.flatpickr-day.week.selected {
  border-radius: 0;
  box-shadow: -5px 0 0 #1dc6c8, 5px 0 0 #1dc6c8;
}

.flatpickr-months .flatpickr-prev-month:hover,
.flatpickr-months .flatpickr-next-month:hover {
  color: #fd962c;
}

.flatpickr-months .flatpickr-prev-month:hover svg,
.flatpickr-months .flatpickr-next-month:hover svg {
  fill: #fd962c;
}

.flatpickr-day.today {
  border-color: #fd962c;
}

.flatpickr-day.today:hover,
.flatpickr-day.today:focus {
  border-color: #fd962c;
  background: #fd962c;
  color: #fff;
}

span.flatpickr-day.today:not(.selected),
span.flatpickr-day.prevMonthDay.today:not(.selected),
span.flatpickr-day.nextMonthDay.today:not(.selected) {
  border-color: #e9e9e9;
  border-bottom-color: #fd962c;
}

span.flatpickr-day.today:not(.selected):hover,
span.flatpickr-day.prevMonthDay.today:not(.selected):hover,
span.flatpickr-day.nextMonthDay.today:not(.selected):hover {
  border: 1px solid #fd962c;
}

span.flatpickr-day.startRange,
span.flatpickr-day.prevMonthDay.startRange,
span.flatpickr-day.nextMonthDay.startRange,
span.flatpickr-day.endRange,
span.flatpickr-day.prevMonthDay.endRange,
span.flatpickr-day.nextMonthDay.endRange {
  border-color: #1dc6c8;
}
